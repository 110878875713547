<script setup>
  
  // Add .error-page to .mainBlock
  const mainBl = document.getElementById("mainBlock");
  mainBl.classList.add("error-block");

</script>

<template>
    <h1 class="error">
      {{ $t("general.no_page")}}
    </h1>
</template>

<style lang="scss">

  .error {
    font-size: 48px;
    color: #fff;
    text-shadow: 0 0 10px #00000042;
    font-weight: 900;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  .error-block {
    background-color: transparent;
    max-width: 100%;
  }
</style>