<template>
  <div class="stars">
    <img src="@/assets/img/Star.svg" alt="star" class="stars__star stars__star--big">
    <img src="@/assets/img/Star.svg" alt="star" class="stars__star stars__star--small stars__lb">
    <img src="@/assets/img/Star.svg" alt="star" class="stars__star stars__star--small stars__ct">
    <img src="@/assets/img/Star.svg" alt="star" class="stars__star stars__star--small stars__rt">
  </div>
</template>

<style lang="scss">
  .stars {
    position: relative;
    &__star {
      position: absolute;
      &--big {
        width: 1.2rem;
        transform: rotate(-10deg);
      }
      &--small {
        width: 0.5rem;
      }
    }
    &__lb {
      bottom: -1.2rem;
      left: -1.2rem
    }
    &__ct {
      top: -1rem;
      left: -0.6rem;
    }
    &__rt {
      top: -0.8rem;
      left: 1.5rem;
    }
  }
</style>