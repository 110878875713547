export default {
  "general": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "pswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "vali_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "help_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
    "no_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, cette page n'existe pas :("])},
    "retour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "pswd_no_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas ou comportent moins de 6 caractères"])},
    "error-signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ou mot de passe incorrect"])},
    "serverErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelque chose s'est mal passé :( Réessayez plus tard s'il vous plaît"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Maternelle Montessori"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes consentements🍪"])}
  },
  "signIn": {
    "prompt-p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez souscrit un abonnement à La Maternelle Montessori à travers un site Bayard - Milan."])},
    "prompt-p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En 3 étapes, vous allez activer votre compte pour jouer avec notre Application."])},
    "prompt-p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez les identifiants du compte Bayard - Milan."])},
    "errorKeycloak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet abonnement est confirmé. Pour choisir un mot de passe et profiter de La Maternelle Montessori, ouvrez l'email intitulé \"Démarrez votre abonnement à La Maternelle Montessori\" et suivez les 2 étapes."])}
  },
  "createEdoki": {
    "prompt-p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes ravis de vous accueillir dans La Maternelle Montessori."])},
    "prompt-p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour activer notre service, veuillez créer un compte EDOKI CLUB qui vous donnera accès à notre Application La Maternelle Montessori."])},
    "email_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser l’email du compte Bayard - Milan"])},
    "nwemail_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser un nouveau email"])},
    "prompt-p2-2a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte Edoki Club:"])},
    "pswd-fgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "prompt-p2-2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un mot de passe (6 caractères minimum) pour votre compte EDOKI CLUB."])},
    "prompt-p2-2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mot de passe vous servira à accéder à notre contenu dans l'Application."])},
    "error-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, mais ce compte n'a pas d'achat relié à La Maternelle Montessori."])},
    "cust_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le service client"])},
    "vali_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un email valide"])}
  },
  "success": {
    "prompt-p3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations!"])},
    "prompt-p3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant télécharger notre application mobile"])},
    "prompt-p4-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installez l'application sur votre appareil préféré."])},
    "prompt-p4-b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton EDOKI CLUB et connectez-vous avec votre compte."])}
  },
  "resetPswd": {
    "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un nouveau mot de passe pour le compte suivant"])},
    "prompt-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit être au moins de 6 caractères"])},
    "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de confirmation:"])},
    "prompt-f1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous enverrons un e-mail à cette adresse e-mail pour confirmer la réinitialisation de votre mot de passe."])},
    "vali_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "resend_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ré-envoyer l'email"])}
  },
  "forgotPswd": {
    
  },
  "connect": {
    "bayard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte de Bayard"])},
    "edoki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte d'Edoki"])},
    "start_subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débuter votre abonnement"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les comptes suivants seront utilisés pour démarrer l'abonnement"])}
  }
}