export default {
  "general": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "pswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "vali_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "help_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "no_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, this page does not exist :("])},
    "retour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
    "pswd_no_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match or are less than 6 characters"])},
    "error-signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail or password is incorrect"])},
    "serverErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong :( Try again later please"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montessori Preschool"])},
    "consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent choices🍪"])}
  },
  "signIn": {
    "prompt-p1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have subscribed to Montessori Preschool through Bayard - Milan"])},
    "prompt-p1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By completing the following 3 steps, you will activate your account to play with our app."])},
    "prompt-p1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Bayard - Milan account identifiers."])},
    "errorKeycloak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription is confirmed. To set up a password and start using Montessori Preschool, open the email in your inbox and follow the 2 steps."])}
  },
  "createEdoki": {
    "prompt-p2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are delighted to welcome you to Montessori Preschool."])},
    "prompt-p2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To have access to our educatonal app, please create an an Edoki Club account."])},
    "email_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Bayard - Milan account email"])},
    "nwemail_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a new email"])},
    "prompt-p2-2a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to your Edoki Club account:"])},
    "pswd-fgt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "prompt-p2-2b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password (at least 6 characters) for your Edoki Club account."])},
    "prompt-p2-2c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password will be used to access the content on our app."])},
    "error-p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. This account does not have any purchases related to Montessori Preschool."])},
    "cust_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Customer Service"])},
    "vali_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])}
  },
  "success": {
    "prompt-p3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
    "prompt-p3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now download our mobile app"])},
    "prompt-p4-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install the application on your preferred device."])},
    "prompt-p4-b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once downloaded, click the Edoki Club button and login in to your account."])}
  },
  "resetPswd": {
    "prompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a new password for the following account"])},
    "prompt-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 6 characters"])},
    "repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation code:"])},
    "prompt-f1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send you an email to this email address to confirm your password reset."])},
    "vali_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "resend_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend email"])}
  },
  "forgotPswd": {
    
  },
  "connect": {
    "bayard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Bayard Account"])},
    "edoki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Edoki Account"])},
    "start_subs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start your subscription"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following accounts will be used to start the subscription"])}
  }
}