<script setup>
  import StepsComponent from './elements/StepsComponent.vue';
  import PasswordInput from './elements/password-input.vue';
  import { ref } from 'vue';
  import axios from 'axios';
  import router from '@/router';

  const inputId = "createPswd";
  const hideEye = "createHideEye";
  const showEye = "createShowEye";

  const hasError = ref(false);
  const clicked = ref(false);

  const urlAPI = process.env.VUE_APP_URL_EDOKI_LOGIN;
  const checkSubsUrl = process.env.VUE_APP_URL_EDOKI_CHECK_SUBSCRIPTION;

  const edokiEmail = localStorage.edokiEmail;

  async function login() {
    const pswdInputValue = document.getElementsByName("passwordInp")[0];

    hasError.value = false;

    const user = {
      "email": edokiEmail,
      "password": pswdInputValue.value,
    }

    clicked.value = true;

    try {
      const res = await axios.post(urlAPI, user);
      localStorage.setItem('auth_token', res.data.access_token);
      clicked.value = false;

      const check = await axios.get(`${checkSubsUrl}${edokiEmail}`);

      if(check.data) {
        router.push({name: 'successPage'});
      } else {
        localStorage.setItem('token', res.data.access_token)
        router.push({name: 'validateConnexion'});
      }
    } catch(error) {
      clicked.value = false;
      hasError.value = true;
    }
  }

  function goToForgotPage() {
    router.push({ name: 'forgotPassword' })
  }

  function returnToCreate() {
    router.push({ name: 'newEdoki' })
  }
</script>

<template>
  <StepsComponent :step-number="2" />
  
  <div class="title-label mb-3">{{ $t("createEdoki.prompt-p2-2a") }}</div>
  <div class="title-label email mb-4">{{ edokiEmail }}</div>
  
  <div class="password px-4">
    <div class="mb-2 px-2">{{ $t("general.pswd") }}</div>
    <PasswordInput :input-id="inputId" :hide-id="hideEye" :show-id="showEye" v-on:keypress.enter="login"/>
    <div class="error-message mb-3 px-2" v-if="hasError">{{ $t("general.error-signIn") }}</div>
    
    <button type="submit" class="primary-button" @click="login" v-if="!clicked">
      {{ $t("general.vali_button") }}
    </button>
    <div class="loader_wrap">
      <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="clicked">
    </div>

    <div class="forgot-password mt-4">
      <button class="forgot-password_button" @click="goToForgotPage">
        {{ $t("createEdoki.pswd-fgt") }}
      </button>
    </div>
  </div>

  <div class="return">
    <button type="button" class="return-link" @click="returnToCreate">{{ $t("general.retour") }}</button>
  </div>
</template>

<style lang="scss">
  .forgot-password {
    text-align: center;
    
    &_button {
      color: #92b2d9;
      border: none;
      background: transparent;
      text-decoration: underline;
      transition: all 0.3s;

      &:hover {
        color: #7d9abd;
      }
    }
  }

  .error-message {
    color: #f00;
    font-size: 0.7rem;
  }
</style>