<script setup>
  import router from "@/router";
  import StepsComponent from "./elements/StepsComponent.vue";
  import { ref } from 'vue';
  
  const emailValue = ref('');
  const showChoice = ref(true);
  const showReturn = ref(false);
  const hasError = ref(false);
  const isDisabled = ref(false);
  const clicked = ref(false);
  
  const urlAPI = process.env.VUE_APP_URL_EDOKI_ACC_STATE;
  const bayard = localStorage.bayardEmail;

  function usePurchaseEmail () {
    showChoice.value = false;
    showReturn.value = true;
    emailValue.value = bayard;
    isDisabled.value = true;
  }

  function useNewEmail() {
    showChoice.value = false;
    showReturn.value = true;
    emailValue.value = '';
    isDisabled.value = false;
  }

  function returnToChoose() {
    showChoice.value = true;
    hasError.value = false;
  }

  async function checkAccount() {
    const validate = isEmail();

    clicked.value = true;

    try {
      if(!validate) {
        throw new Error();
      }

      const inputValue = emailValue.value;
      localStorage.setItem('edokiEmail', inputValue);
      const res = await fetch(urlAPI + inputValue).then(response => response.json());

    if(res.exist && res.hasPwd) {
      clicked.value = false;
      router.push({ name: 'enterEdokiPswd'});
    } else if(res.exist && !res.hasPwd){
      clicked.value = false;
      router.push({ name: 'forgotPassword'});
    } else {
      clicked.value = false;
      router.push({ name: 'newEdokiPswd'});
    }
    } catch(error) {
      hasError.value = true;
      clicked.value = false;
    }
  }

  function isEmail() {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailValue.value))
  {
    return true
  }
    return false
  }
</script>

<template>

  <div class="create-account">
    
    <StepsComponent :step-number="2" />

    <div class="title-label mb-4">{{ $t("createEdoki.prompt-p2-1") }}</div>
    <div class="sub-title-label mb-4">{{ $t("createEdoki.prompt-p2-2") }}</div>

    <div class="p-2">{{ $t("general.email")}}:</div>

    <div class="wrap mb-lg-5" v-if="showChoice">
      <button type="button" class="primary-button mb-2" @click="usePurchaseEmail"> {{ $t("createEdoki.email_button") }}</button>
      <button type="button" class="primary-button mb-2" @click="useNewEmail"> {{ $t("createEdoki.nwemail_button") }}</button> 
    </div>
    <div class="validateEmail mb-lg-5" v-else>
      <input class="form-field-input mb-2" id="emailInp" v-model="emailValue" :disabled="isDisabled" v-on:keypress.enter="checkAccount"/>
      <div class="error-message mb-3 px-2" v-if="hasError">{{ $t("createEdoki.vali_email") }}</div>
        
      <button type="button" class="primary-button" @click="checkAccount" v-if="!clicked">
        {{ $t("general.vali_button")}}
      </button>
      <div class="loader_wrap">
        <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="clicked">
      </div>
    </div>

    <div class="return" v-if="showReturn">
      <button type="button" class="return-link" @click="returnToChoose">{{ $t("general.retour") }}</button>
    </div>
  </div>
  
</template>
