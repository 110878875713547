<script setup>
  import StepsComponent from './elements/StepsComponent.vue';
  import PasswordInput from './elements/password-input.vue';
  import { ref } from 'vue';
  import axios from 'axios';
  import router from '@/router';
  import i18n from '@/i18n';

  const inputId = "createPswd";
  const hideEye = "createHideEye";
  const showEye = "createShowEye";

  const inputId1 = "repeatPswd";
  const hideEye1 = "createHideEye1";
  const showEye1 = "createShowEye1";

  const urlSignIn = process.env.VUE_APP_URL_EDOKI_LOGIN;
  const urlSignup = process.env.VUE_APP_URL_EDOKI_SIGNUP;

  const hasError = ref(false);
  const clicked = ref(false);

  const userEmail = localStorage.edokiEmail;

  async function signUp() {
    const newPswd = document.getElementById("createPswd");
    const repeatPswd = document.getElementById("repeatPswd");
    let newUser = {};

    clicked.value = true;
    
    try {
      if(newPswd.value !== repeatPswd.value || newPswd.value === null || repeatPswd.value === null || newPswd.value.length < 6) {
        throw new Error;
      } else {
        newUser = {
          "email": userEmail,
          "password": repeatPswd.value,
          "locale": i18n.global.locale._value
        }

        await axios.post(urlSignup, newUser);
        const res = await axios.post(urlSignIn, newUser);

        localStorage.setItem('auth_token', res.data.access_token);

        clicked.value = false;
        router.push({name: 'validateConnexion'});
      }
    } catch(error) {
      clicked.value = false;
      hasError.value = true;
    } 
  }

</script>

<template>
    <StepsComponent :step-number="2" />

    <div class="title-label email mb-4">{{ userEmail }}</div>
    <div class="title-label mb-4">{{ $t("createEdoki.prompt-p2-2b") }}</div>

    <div class="password px-4">
      <form action="">
        <div class="mb-2 px-2">{{ $t("general.pswd") }}</div>
        <div class="password__description mb-2 px-2">
          {{ $t("createEdoki.prompt-p2-2c") }}
        </div>

        <PasswordInput :input-id="inputId" :hide-id="hideEye" :show-id="showEye"/>
        <PasswordInput :input-id="inputId1" :hide-id="hideEye1" :show-id="showEye1"/>

        <div class="error-message mb-3 px-2" v-if="hasError">{{ $t("general.pswd_no_match") }}</div>

        <button type="submit" class="primary-button" @click="signUp" v-if="!clicked">
          {{ $t("general.vali_button") }}
        </button>
        <div class="loader_wrap">
          <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="clicked">
        </div>
      </form>
    </div>

    
    
</template>

<style lang="scss">
  @import "../assets/styles/edoki-variables.scss";

  .password {
    &__description {
      color: #bbc9d0;
    }
    &__input {
      position: relative;
    }
    &__new {
      transform: translateY(-140%);
    }
  }

  .email {
    color: $text-color-dark
  }
</style>