<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  inputId: String,
  showId: String,
  hideId: String
})

function togglePassword() {
  const show = document.getElementById(props.showId);
  const hide = document.getElementById(props.hideId);
  const passwordInp = document.getElementById(props.inputId);


  if (show.style.display == "block" && hide.style.display == "none") {
    show.style.display = "none";
    hide.style.display = "block";

    passwordInp.setAttribute("type", "text");
  } else {
    show.style.display = "block";
    hide.style.display = "none";

    passwordInp.setAttribute("type", "password");
  }
}
</script>

<template>
<div class="password__input">
      <input type="password" :id="props.inputId" name="passwordInp" class="form-field-input">
      <button type="button" class="form-field-pass password__new" tabindex="-1" @click="togglePassword">
          <img src="../../assets/img/ShowPasswordBlue.png" alt="showPass" :id="props.showId" style="display: block;">
          <img src="../../assets/img/HidePasswordBlue.png" alt="hidePass" :id="props.hideId" style="display: none;">
      </button>
    </div>
</template>

<style lang="scss">
  img[alt="hidePass"] {
    top: 7px;
  }
</style>