<script setup>
  import Footer from "./components/FooterBayard.vue";
  import Header from "./components/HeaderBayard.vue";
  import createI18n from "./i18n";
  document.title = createI18n.global.t('general.title');
</script>

<template>
  <div class="site-layout absolute-fill">
    <Header />
    <main class="py-5">
      <div class="main-block" id="mainBlock">
        <router-view></router-view>
      </div>           
    </main>
    <Footer />
  </div>

</template>

<style lang="scss">
  @import "./assets/styles/main.scss";

  .site-layout{
    display: grid;
    grid-template-rows: auto 100fr auto;
  }

  .main-block {
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 3rem 4rem;
    min-height: 45rem;

    @media (min-width: 601px) {
      width: 35rem;
    }

    @media (max-width: 600px) {
      padding: 2rem 1rem;
      max-width: 28rem;
    }
  }
</style>
