<script setup>
import { onMounted, defineProps, ref } from 'vue';

  const isCurrent = ref([false, false, false]);
  const isChecked = ref([false, false]);

  const props = defineProps({
    stepNumber: Number
  })

  onMounted(() => {
    activeStep();
  });

  function activeStep() {
    for(let i = 0; i <= props.stepNumber - 1; i++) {
      if(i < props.stepNumber - 1) {
        isChecked.value[i] = true;
      } else if(i === props.stepNumber - 1) {
        isCurrent.value[i] = true;
      }
    }
  }
</script>

<template>
    <!-- Steps -->
    <div class="steps">
      <div class="steps-step steps-step--active" v-if="isCurrent[0]">1</div>
      <div class="steps-step steps-step--check" v-if="isChecked[0]"></div>

      <div class="steps-line steps-line--active" v-if="isChecked[0]"></div>
      <div class="steps-line" v-else></div>

      <div class="steps-step steps-step--active" v-if="isCurrent[1]">2</div>
      <div class="steps-step steps-step--check" v-else-if="isChecked[1]"></div>
      <div class="steps-step" v-else></div>

      <div class="steps-line steps-line--active" v-if="isChecked[1]"></div>
      <div class="steps-line" v-else></div>

      <div class="steps-step steps-step--active" v-if="isCurrent[2]">3</div>
      <div class="steps-step" v-else></div>
    </div>
</template>

<style lang="scss">
  @import "@/assets/styles/edoki-variables.scss";

  .steps {
    display: grid;
    grid-template-columns: 50px auto 50px auto 50px;
    align-items: center;
    margin-bottom: 2rem;

    &-step {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: $secoundary-color;
      z-index: 10;

      &--active {
          background-color: $primary-color;
          color: #fff;
          font-size: 32px;
          font-weight: 900;
          text-align: center;

          img {
            width: 30px;
          }
      }

      &--check {
        background-color: $primary-color;
        background-image: url('@/assets/img/Check.png');
        background-position: center;
        background-size: auto;
        background-repeat: no-repeat;
        background-size: 2rem;
      }
    }

    &-line {
      height: 10px;
      margin: 0 -1px;
      background-color: $secoundary-color;

      &--active {
        background-color: $primary-color;
      }
    }
  }
</style>