<script setup></script>

<template>
  <footer class="footer p-4">
    <div class="footer__logos">
      <img src="../assets/img/Logo_Bayard_Jeunesse_RVB.png" alt="BayardLogo" class="footer__bayard">
          <img src="../assets/img/Milan_Logo.png" alt="MilanLogo" class="footer__milan">
          <img src="../assets/img/LogoMaternelleMontessori.png" alt="MontessoriLogo" class="footer__montessori" v-if="$i18n.locale === 'fr'">
          <img src="../assets/img/Montessori_EN.svg" alt="MontessoriLogo" class="footer__montessori" v-if="$i18n.locale === 'en'">
          
    </div> 
    <div class="footer__faq">
      <div class="footer__faq-icon"><img src="../assets/img/FAQ_Icon.svg" alt="FAQicon"></div>
        <div class="footer__links">
          <a href="javascript:Didomi.preferences.show()" class="footer__faq-link">{{ $t('general.consent')}}</a>
          <a href="https://edokiacademy.freshdesk.com/fr/support/solutions/folders/44001232982" class="footer__faq-link" target="_blank">{{ $t("general.help_button") }}</a>
        </div>
      <div class="footer__locale-switcher">
      <select v-model="$i18n.locale" class="footer__select">
        <option value="en">En</option>
        <option value="fr">Fr</option>
      </select>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
  @import "../assets/styles/edoki-variables.scss";

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 920px) {
      flex-direction: column;
      gap: 1rem;
    }
    &__logos {
      display: flex;
      align-items: center;
      gap: 1rem;
      @media (max-width: 668px) {
        flex-direction: column;
      }
    }
    &__faq {
        display: flex;
        align-items: center;
        justify-content: center;
        &-icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        &-link {
            font-weight: 700;
            color: $primary-color;
            font-size: 20px;
            @media (max-width: 920px) {
              font-size: 18px;
            }
        }
    }
    &__bayard {
        width: 185px;
        height: 44px;
    }
    &__milan {
        width: 78px;
        height: 78px;
        margin-left: 30px;
        @media (max-width: 767px) {
          margin-left: 15px;
        }
    }
    &__montessori {
        width: 184px;
        height: 50px;
        margin-left: 30px;
        @media (max-width: 767px) {
          margin-left: 15px;
        }
    }
    &__locale-switcher {
      margin-left: 2rem;
    }
    &__select {
      border: none;
      font-size: 20px;
      color: #00b9f2;
      background-color: #ddebf2;
      padding: 5px 10px;
      border-radius: 2rem;
    }
    &__links {
      display: flex;
      flex-direction: column;
    }
}
</style>
