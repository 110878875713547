<script setup>
import router from '@/router/index.js';

  function goHome() {
    router.push({name: "home"})
  }
</script>

<template>
  <header class="header p-4">
    <button class="header__button" @click="goHome">
      <img src="../assets/img/LogoMaternelleMontessori.png" alt="logoMontessori" class="header__logo" v-if="$i18n.locale === 'fr'">
      <img src="../assets/img/Montessori_EN.svg" alt="logoMontessori" class="header__logo" v-if="$i18n.locale === 'en'">
    </button> 
  </header>
</template>

<style lang="scss">
  .header {
    display: flex;
    justify-content: center;
    
    &__logo {
      height: 5rem;
    }

    &__button {
      background: transparent;
      border: none;
    }
  }
</style>