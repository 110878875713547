import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueGtag from 'vue-gtag';
import { VueCookieNext } from 'vue-cookie-next';

const app = createApp(App);

  app.use(i18n)
  .use(router)
  .use(VueCookieNext)
  .use(VueGtag, {
    config: { id: 'UA-36397825-17' },
    router,
    enabled: false
  });

  app.provide('gtag', app.config.globalProperties.$gtag);

  app.mount("#app");

  
