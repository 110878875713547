<script setup>
  import StepsComponent from './elements/StepsComponent.vue';
  import StarsElement from './elements/stars-element.vue';

  const email = localStorage.edokiEmail
</script>

<template>
  <div class="success">
    <StepsComponent :step-number="3" />

    <div class="success__greeting my-4">
      <div class="success__stars">
        <StarsElement />
      </div>
      <div class="title-label px-4">{{ $t("success.prompt-p3-1") }}</div>
      <div class="success__stars success__stars--mirror">
        <StarsElement />
      </div>
    </div>

    <div class="title-label email mb-3">
      {{ email }}
    </div>

    <div class="title-label">
      {{ $t("success.prompt-p3-2")}}
    </div>

    <div class="success__step-container mt-4">
      <div class="success__step">1</div>
      <div class="success__subtitle">
        {{ $t("success.prompt-p4-a")}}
      </div>
      <div class="success__links links mt-3">
        <a href="https://appsto.re/ca/lOY2db.i?l=en" class="links__link">
          <img src="@/assets/img/LogoAppleCircle.svg" alt="AppleStore" class="links__logo">
          <div class="links__text">
            Apple Store
          </div> 
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.edokicademy.montessoriacademy" class="links__link">
          <img src="@/assets/img/LogoPlayStoreCircle.svg" alt="PlayStore" class="links__logo">
          <div class="links__text">
            Play Store
          </div>
        </a>
        <a href="https://www.amazon.com/Edoki-Academy-Montessori-Preschool/dp/B0747ZJ2C2" class="links__link">
          <img src="@/assets/img/LogoAmazonCircle.svg" alt="Amazon" class="links__logo">
          <div class="links__text">
            Amazon
          </div>
        </a>
        <a href="https://appgallery.huawei.com/app/C100388101" class="links__link">
          <img src="@/assets/img/LogoHuaweiCircle.svg" alt="Huawei" class="links__logo">
          <div class="links__text">
            Huawei
          </div>
        </a>
      </div>
    </div>
    <div class="success__step-container mt-3">
      <div class="success__step">2</div>
      <div class="success__subtitle">
        {{ $t("success.prompt-p4-b")}}
      </div>
      <div class="mx-5">
        <img src="@/assets/img/iPadScreen.png" alt="screen" class="success__image">
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/styles/edoki-variables.scss";

  .success {
    &__stars--mirror {
      transform: rotateY(3.142rad);
    }
    &__greeting {
      display: flex;
      justify-content: center;
      gap: 1rem;
      text-transform: uppercase;
    }
    &__step-container {
      position: relative;
      background-color: $primary-color;
      color: #fff;
      border-radius: 2rem;
      padding: 2rem 1rem 2rem 4rem;
      @media (max-width: 567px) {
        padding: 1rem 0.8rem 1rem 2rem;
      }
    }
    &__step {
      position: absolute;
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      border: 3px solid #fff;
      text-align: center;
      font-weight: 800;
      font-size: 3.5rem;
      background-color: $primary-color;
      left: -2.5rem;
      top: 5rem;
      @media (max-width: 567px) {
        width: 3.5rem;
        height: 3.5rem;
        left: -1.5rem;
        top: 3.5rem;
        font-size: 2rem;
      }
    }
    &__image {
      width: 13rem;
      @media (max-width: 767px) {
        width: 10rem;
      }
    }
    &__subtitle {
      font-size: 0.8rem;
    }
  }

  .links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    &__logo {
      width: 4rem;
      z-index: 10;
      @media (max-width: 567px) {
        width: 2rem;
      }
    }
    &__link {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      width: 100%;
      text-decoration: none;
      color: $primary-color;
    }
    &__text {
      background-color: #fff;
      padding: 0.5rem 0.6rem 0.5rem 1rem;
      width: 100%;
      height: 2.2rem;
      border-radius: 2rem;
      transform: translateX(-1.2rem);
      font-size: 0.9rem;
      text-align: center;
      @media (max-width: 567px) {
        font-size: 0.7rem;
        padding: 0.3rem 0.4rem 0.3rem 0.8rem;
        height: 1.5rem;
      }
    }
  }
</style>