<script setup>
  import { ref } from 'vue';
  import router from '@/router';
  import PasswordInput from './elements/password-input.vue';
  import StepsComponent from './elements/StepsComponent.vue';
  import axios from 'axios';

  const hasError = ref(false);
  const clicked = ref(false);
  const showForm = ref(false);
  const showSendRequestButton = ref(true);
  const confCode = ref("");
  const noMatch = ref(false);

  const userEmail = localStorage.edokiEmail;
  const loginUrl = process.env.VUE_APP_URL_RESET_REQUEST;
  const resetUrl = process.env.VUE_APP_URL_RESET_PASSWORD;

  const inputId = "createPswd";
  const hideEye = "createHideEye";
  const showEye = "createShowEye";

  const inputId1 = "repeatPswd";
  const hideEye1 = "createHideEye1";
  const showEye1 = "createShowEye1";

  async function sendRequest() {
    clicked.value = true;
    try {
      await axios.get(`${loginUrl}${userEmail}`);
      clicked.value = false;
      showSendRequestButton.value = false;
      showForm.value = true;
    } catch(error) {
      hasError.value = true;
      clicked.value = false;
    }
  }

  async function resetPswd() {
    const newPswd = document.getElementById("createPswd");
    const repeatPswd = document.getElementById("repeatPswd");

    clicked.value = true;
    
    try {
      if(newPswd.value !== repeatPswd.value || newPswd.value === null || repeatPswd.value === null || newPswd.value.length < 6) {
        throw new Error;
      }

      await axios.get(`${resetUrl}?email=${userEmail}&password=${newPswd.value}&validationCode=${confCode.value}`);

      clicked.value = false;
      router.push({name: 'enterEdokiPswd'});
    } catch(error) {
      clicked.value = false;
      noMatch.value = true;
    } 
  }

  function returnToCreate() {
    router.push({name: "enterEdokiPswd"});
  }
</script>

<template> 
  <StepsComponent :step-number="2" />

  <div class="title-label mb-4">
    {{ $t("resetPswd.prompt-f1") }}
  </div>
  <div class="title-label email mb-4">
    {{ userEmail }}
  </div>

  <button class="primary-button" @click="sendRequest" v-if="!clicked && showSendRequestButton">
    {{ $t("resetPswd.vali_button") }}
  </button>
    
  <div class="loader_wrap">
    <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="clicked">
  </div>
  <div class="error-message mb-3 px-2" v-if="hasError">{{ $t("general.serverErr") }}</div>

  <div class="reset-form" v-if="showForm">
    <div class="mb-2 px-2">{{ $t("general.pswd") }}</div>
    <div class="password__description mb-1 px-2">
      {{ $t("resetPswd.prompt-2") }}
    </div>
    <PasswordInput :input-id="inputId" :hide-id="hideEye" :show-id="showEye" v-on:keypress.enter="resetPswd"/>

    <div class="password__description mb-1 px-2">
      {{ $t("resetPswd.repeat") }}
    </div>
    <PasswordInput :input-id="inputId1" :hide-id="hideEye1" :show-id="showEye1" v-on:keypress.enter="resetPswd" />

    <div class="error-message mb-3 px-2" v-if="noMatch">{{ $t("general.pswd_no_match") }}</div>

    <div class="mb-2 px-2">
      {{ $t("resetPswd.confirm") }}
    </div>
    <input class="form-field-input" v-model="confCode" v-on:keypress.enter="resetPswd">

    <button class="primary-button" @click="resetPswd" v-if="!clicked">
      {{ $t("general.vali_button") }}
    </button>
    <div class="loader_wrap">
      <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="clicked">
    </div>

    <div class="resend">
      <button class="resend-link mt-2" @click="sendRequest" >{{ $t("resetPswd.resend_button")}}</button>
    </div>
    
  </div>

  <div class="return">
    <button type="button" class="return-link" @click="returnToCreate">{{ $t("general.retour") }}</button>
  </div>
</template>

<style lang="scss">
  .resend {
    display: flex;
    justify-content: center;
    &-link {
      border: none;
      background: transparent;
      text-decoration: underline;
    }
  }
</style>
