<script setup>
import i18n from '@/i18n';
import router from '@/router';

import axios from 'axios';
import { ref } from 'vue'

  const bayardEmail = localStorage.bayardEmail;
  const edokiEmail = localStorage.edokiEmail;
  const hasError = ref(false);
  const waitingForRequest = ref(false);

  const startSubs = process.env.VUE_APP_URL_EDOKI_START_SUBSCRIPTION;
  const auth_token = localStorage.auth_token;

  const config = {
    method: 'post',
    url: `${startSubs}${encodeURIComponent(edokiEmail)}/startbayardsubscription`,
    headers: { 
      'Authorization': `Bearer ${auth_token}`, 
      'Content-Type': 'application/json'
    },
    data : {
      "KeycloakId": localStorage.keycloakId,
      "Email": localStorage.edokiEmail,
      "language": i18n.global.locale._value
    }
  }

  async function startSubscription() {
    waitingForRequest.value = true;
    await axios(config)
      .then(response => {
        if(!response.data.success) {
          hasError.value = true;
        } else {
          router.push({name: "successPage"})
        }
      })
      .catch(error => {
        console.log("Request error when calling startSubscription");
        console.log(error);
        hasError.value = true;
      })
      .then(() => {
        waitingForRequest.value = false;
      });
  }

</script>

<template>
  <div class="title-label mb-4">{{ $t("connect.message") }}</div>
  <div class="title-label mb-4">{{ $t("connect.bayard") }}</div>
  <div class="title-label email mb-4"> {{ bayardEmail }} </div>
  <div class="title-label mb-4">{{ $t("connect.edoki") }}</div>
  <div class="title-label email mb-4"> {{ edokiEmail }} </div>

  <button class="primary-button" @click="startSubscription" v-if="!waitingForRequest">
    {{ $t("connect.start_subs")}}
  </button>
  <div class="loader_wrap">
        <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="waitingForRequest">
  </div>

  <div class="error-message mt-3" v-if="hasError">{{ $t("general.serverErr") }}</div>
</template>