import SignInPageVue from "@/components/SignInPage.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import NoPurchaseVue from "@/components/NoPurchase.vue";
import CreateNewAccVue from "@/components/CreateNewAcc.vue";
import CreateNewPasswordVue from "@/components/CreateNewPassword.vue";
import HaveEdokiAccountVue from "@/components/HaveEdokiAccount.vue";
import SuccessPageVue from "@/components/SuccessPage.vue"
import { createRouter, createWebHistory } from "vue-router";
import NoPasswordVue from "@/components/NoPassword.vue";
import ConnexionPageVue from "@/components/ConnexionPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: SignInPageVue,
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/no-purchase",
    name: "noPurchase",
    component: NoPurchaseVue,
  },
  {
    path: "/create-edoki-account",
    name: "newEdoki",
    component: CreateNewAccVue,
  },
  {
    path: "/create-edoki-password",
    name: "newEdokiPswd",
    component: CreateNewPasswordVue,
    props: true
  },
  {
    path: "/enter-edoki-password",
    name: "enterEdokiPswd",
    component: HaveEdokiAccountVue,
    props: true
  },
  {
    path: "/success",
    name: "successPage",
    component: SuccessPageVue,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: NoPasswordVue,
    props: true
  },
  {
    path: "/validate-connexion",
    name: "validateConnexion",
    component: ConnexionPageVue,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  root: '/'
});

export default router;
