<script setup>
  import Steps from './elements/StepsComponent.vue';
  import passwordInputVue from './elements/password-input.vue';
  import router from '@/router';
  import { ref } from 'vue'
  import axios from 'axios';

  const id_passwordField = "signInPswd";
  const id_hideEye = "signInHideEye";
  const id_showEye = "signInShowEye";

  const showSignInError = ref(false);
  const isLoading = ref(false);
  const hasAccount = ref(false);
  const currentDate = new Date().toISOString().substr(0, 10);

  const url = process.env.VUE_APP_URL_BAYARD_LOGIN;
  const urlCheckKeycloakId = process.env.VUE_APP_URL_CHECK_UNIQUE_KEYCLOAK;

  async function signIn() {
    const dom_emailInput = document.getElementById("Email");
    const dom_pswdInput = document.getElementById(id_passwordField);

    // Encode URL
    var email = encodeURIComponent(dom_emailInput.value);
    var pswd = encodeURIComponent(dom_pswdInput.value);
    const urlAPI = `${url}?bayardUserName=${email}&bayardPassword=${pswd}`;

    localStorage.setItem('bayardEmail', dom_emailInput.value);

    isLoading.value = true;

    try {
      showSignInError.value = false;
      hasAccount.value = false;
      const res = await fetch(urlAPI).then(response => response.json());

      if(res.hasError === true || res.status === 400) {
        showSignInError.value = true;
        throw new Error();
      }

      // Check account is not expired
      if(!res.accountState || res.accountState.ending_at < currentDate) {
        router.push({ name: "noPurchase" })
      }
      else {
        const isUnique = await isKeycloakUnique(res.uidKeyCloak);

        if(isUnique) {
          localStorage.setItem('keycloakId', res.uidKeyCloak);
          router.push({ name: "newEdoki" })
        }
        else {
          hasAccount.value = true;
        }
      }

    }
    catch(error) {
      showSignInError.value = true;
    }
    
    isLoading.value = false;
  }

  async function isKeycloakUnique(keycloakId) {
    const res = await axios.get(`${urlCheckKeycloakId}${keycloakId}`);
    return !res.data;
  }

</script>

<template>
  <div class="sign">

    <Steps :step-number="1" />

    <div class="title-label mb-4">{{ $t("signIn.prompt-p1-1") }}</div>
    <div class="sub-title-label mb-4"> {{ $t("signIn.prompt-p1-2")}}</div>

    <div class="px-5">
      <div class="p-2">
          {{ $t("signIn.prompt-p1-3") }}
      </div>

      <label for="#Email" class="form-field-label px-2">{{ $t("general.email")}}:</label>
      <input type="email" id="Email" class="form-field-input" v-on:keypress.enter="signIn">

      <label :ref_for="id_passwordField" class="form-field-label px-2">{{ $t("general.pswd")}}:</label>
      <passwordInputVue :input-id="id_passwordField" :hide-id="id_hideEye" :show-id="id_showEye" v-on:keypress.enter="signIn"/>
      
      <div class="error-message mb-3 px-2" v-if="showSignInError">{{ $t("general.error-signIn") }}</div>
      <div class="error-message mb-3 px-2" v-if="hasAccount">{{ $t("signIn.errorKeycloak") }}</div>
      
      <button class="primary-button" @click="signIn" v-if="!isLoading">
        {{ $t("general.vali_button")}}
      </button>
      <div class="loader_wrap mt-2">
        <img src="@/assets/img/loading_.gif" alt="loader" class="loader" v-if="isLoading">
      </div>
    </div>
    
    <!-- Img: Little Girls -->
    <div class="sign__girl">
      <img src="../assets/img/Girl.png" alt="girl" class="sign__girl-img">
    </div>

    <!-- Img: Little Boy -->
    <div class="sign__boy">
      <img src="../assets/img/Boy.png" alt="boy" class="sign__boy-img">
    </div>

  </div>
</template>

<style lang="scss">
  @import "../assets/styles/edoki-variables.scss";

  .sign {
    position: relative;

    &__girl {
      position: absolute;
      top: 39%;
      right: 100%;

      &-img {
        height: 24rem;

        @media (max-width: 1139px) {
          display: none;
        }
      }
    }
    
    &__boy {
      position: absolute;
      top: 29%;
      left: 100%;

      &-img {
        height: 27rem;

        @media (max-width: 1139px) {
          display: none;
        }
      }
    }

    &__password {
      position: relative;
      width: 100%;
    }

    &__link {
      width: 100%;
    }
  }
</style>